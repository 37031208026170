import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

const SwitcherLabel = styled.h2`
  padding: 0 ${props => props.theme.spacing.gap};
  margin: 0;
  display: inline-block;
`

const Switcher = styled.nav`
  border-bottom: 1px solid ${props => props.theme.colors.greyLight};

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;

    li {
      margin: 0;
      width: 100%;
    }
  }
`

const menuItemHover = css`
  text-decoration: none;
  color: ${props => props.theme.colors.navyHover};
  border-bottom: 8px solid ${props => props.theme.colors.aquaBlue};
`

const menuItem = css`
  border-bottom: 8px solid ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.navy};
  cursor: pointer;
  display: block;
  font-family: 'Syne-Bold', sans-serif;
  font-size: ${props => props.theme.type.sizes.tiny};
  font-weight: 600;
  padding: 1rem 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s;
  width: 100%;
  min-height: 100%;

  &:hover {
    ${menuItemHover}
  }

  ${props =>
    props.active &&
    css`
      ${menuItemHover}
    `}

  @media (min-width: ${props => props.theme.responsive.medium}) {
    font-size: ${props => props.theme.type.sizes.small};
  }
`

const StyledLink = styled(Link)`
  ${menuItem}
`

const StyledButton = styled.button`
  ${menuItem}
`

export { StyledButton, SwitcherLabel, Switcher, StyledLink }
