import React from 'react'

import Layout from '../components/Layout'
import MenuSub, { MODE_LINKS, menuContact } from '../components/MenuSub'
import Offices from '../components/Offices'
import SEO from '../components/SEO'
import SEOPageTitle from '../components/SEOPageTitle'
import Section from '../components/Section'
import SlimTitle from '../components/SlimTitle'

const OurOffices = () => {
  const seoNode = {
    title: `Our Offices - Currency and Payments Experts - Zoopay`,
    description:
      'Contact us today for a free quote. You can find our global office details here and the different ways you can contact the team.',
  }

  return (
    <Layout>
      <SEOPageTitle title={seoNode.title} />
      <SEO postNode={seoNode} pagePath="offices" customTitle />
      <MenuSub mode={MODE_LINKS} data={menuContact} />
      <Section>
        <SlimTitle>Our Offices<span>.</span></SlimTitle>
        <Offices />
      </Section>
    </Layout>
  )
}

export default OurOffices
