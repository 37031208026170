import React from 'react'

import { Title } from './styles'

const SectionTitle = ({ as = 'h1', className, children }) => (
  <Title as={as} className={className}>
    {children}
  </Title>
)

export default SectionTitle
