import React from 'react'

import Container from '../Container'

import { Switcher, StyledButton, StyledLink } from './styles'
import { menuTools, menusToolsChart, menuLegal, menuContact } from './data'

const MODE_LINKS = 'ToolsSwitcher/MODE_LINKS'
const MODE_BUTTONS = 'ToolsSwitcher/MODE_BUTTONS'

const MenuSub = ({ activeRange, data, mode, onClick }) => {
  let Tag = Container

  if (mode === MODE_BUTTONS) {
    Tag = 'div'
  }

  const activeStyle = {
    borderBottom: '8px solid #00f0e0',
  }

  return (
    <Tag>
      <Switcher as="nav">
        <ul>
          {data.map(({ id, to, name, range }) => (
            <li key={`ts-${id}`}>
              <>
                {mode === MODE_BUTTONS ? (
                  <StyledButton active={range === activeRange && true} onClick={event => onClick(event, range)}>
                    {name}
                  </StyledButton>
                ) : (
                  <StyledLink to={to} activeStyle={activeStyle}>
                    {name}
                  </StyledLink>
                )}
              </>
            </li>
          ))}
        </ul>
      </Switcher>
    </Tag>
  )
}

export default MenuSub
export { MODE_LINKS, MODE_BUTTONS, menuContact, menuTools, menusToolsChart, menuLegal }
