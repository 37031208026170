import styled from 'styled-components'

const Body = styled.div`
  color: ${props => props.theme.colors.navy};
  font-size: ${props => props.theme.type.sizes.base};
  margin: 0;

  @media (min-width: ${props => props.theme.responsive.medium}) {
    font-size: ${props => props.theme.type.sizes.medium};
  }

  h1,
  h2,
  h3 {
    font-family: ${props => props.theme.type.fonts.base};
    font-weight: ${props => props.theme.type.weights.bold};
    line-height: 1.25;
    margin: 0 0 1rem 0;
    text-transform: uppercase;
  }

  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.25em;
  }

  h3 {
    font-size: 1em;
  }

  p {
    font-size: ${props => props.theme.type.sizes.base};
    line-height: 1.6;
    margin: 0 0 2em 0;

    :first-child {
      font-size: ${props => props.theme.type.sizes.medium};
      font-weight: ${props => props.theme.type.weights.bold};
    }

    @media (min-width: ${props => props.theme.responsive.medium}) {
      font-size: ${props => props.theme.type.sizes.medium};
      :first-child {
        font-size: ${props => props.theme.type.sizes.large};
        font-weight: ${props => props.theme.type.weights.bold};
      }
    }
  }

  a {
    color: ${props => props.theme.colors.oceanBlue};
    transition: ${props => props.theme.animations.transition};

    &:hover {
      color: ${props => props.theme.colors.oceanBlueHover};
    }
  }

  del {
    text-decoration: line-through;
  }

  strong {
    color: ${props => props.theme.colors.navyHover};
    font-weight: ${props => props.theme.type.weights.bold};
  }

  em {
    font-style: italic;
  }

  ul,
  ol {
    margin-bottom: 2rem;
    li {
      line-height: 1.6;
      list-style: disc;
      margin: 1rem 0;
    }
  }

  ol {
    li {
      list-style: decimal;
    }
  }

  hr {
    border: 1px solid ${props => props.theme.colors.secondary};
    margin: 0 0 2rem 0;
  }

  blockquote {
    border-left: 4px solid ${props => props.theme.colors.secondary};
    font-style: italic;
    padding: 0 0 0 0.5em;
  }

  pre {
    background: ${props => props.theme.colors.secondary} !important;
    border-radius: 2px;
    margin: 0 0 2em 0;
    span {
      background: inherit !important;
    }
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
    text-align: left;
  }

  td,
  th {
    border: 1px solid ${props => props.theme.colors.grey};
    font-family: ${props => props.theme.type.fonts.base};
    font-size: ${props => props.theme.type.sizes.base};
    height: 2rem;

    @media (min-width: ${props => props.theme.responsive.medium}) {
      min-width: 150px;
    }

    @media (min-width: ${props => props.theme.responsive.large}) {
      min-width: 200px;
    }
  }

  th {
    background-color: ${props => props.theme.colors.greyLight};
    font-family: ${props => props.theme.type.fonts.base};
    font-size: ${props => props.theme.type.sizes.tiny};
    font-weight: ${props => props.theme.type.weights.bold};
    padding: 0.25rem 0.5rem;

    @media (min-width: ${props => props.theme.responsive.medium}) {
      padding: 0.5rem 1rem;
      font-size: ${props => props.theme.type.sizes.small};
    }

    @media (min-width: ${props => props.theme.responsive.large}) {
      font-size: ${props => props.theme.type.sizes.base};
    }
  }

  td {
    background: ${props => props.theme.colors.white};
    line-height: 1.6;
    padding: 0.25rem;
    vertical-align: top;
    font-size: ${props => props.theme.type.sizes.tiny};

    @media (min-width: ${props => props.theme.responsive.medium}) {
      padding: 0.5rem;
      font-size: ${props => props.theme.type.sizes.small};
    }

    @media (min-width: ${props => props.theme.responsive.large}) {
      padding: 1rem;
      font-size: ${props => props.theme.type.sizes.base};
    }
  }
`

export { Body }
