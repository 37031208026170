import styled from 'styled-components'

const Title = styled.h1`
  text-align: left;
  margin-top: 0;
  font-size:  ${props => props.theme.type.sizes.hardSizes.massive};
  margin-bottom: 30px;
  font-weight:  ${props => props.theme.type.weights.bold};
  max-width: 725px;
  
  span {
    color: ${props => props.theme.colors.aquaBlue};
  }

  @media (max-width: ${props => props.theme.responsive.large}) {
    margin-bottom: 30px;
    font-size: ${props => props.theme.type.sizes.hardSizes.bigger};
  }
  /* @media (max-width: ${props => props.theme.responsive.small}) {
    font-size: ${props => props.theme.type.sizes.hardSizes.base};
  }   */
`

export { Title }
