const menuContact = [
  {
    id: 'contact-contact',
    to: '/contact',
    name: 'Contact',
  },
  {
    id: 'contact-our-office',
    to: '/offices',
    name: 'offices',
  },
]

const menuLegal = [
  {
    id: 'terms-personal',
    to: '/terms-and-conditions-personal',
    name: 'Terms & Conditions Personal',
  },
  {
    id: 'terms-business',
    to: '/terms-and-conditions-business',
    name: 'Terms & Conditions Business',
  },
]

const menuTools = [
  {
    id: 'currency-converter',
    to: '/tools/currency-converter',
    name: 'Currency Converter',
  },
  {
    id: 'iban-checker',
    to: '/tools/iban-checker',
    name: 'IBAN checker',
  },
  {
    id: 'swift-checker',
    to: '/tools/swift-checker',
    name: 'SWIFT checker',
  },
]

const menusToolsChart = [
  {
    id: 'week',
    range: 7,
    name: 'Week',
  },
  {
    id: 'month',
    range: 30,
    name: 'Month',
  },
  {
    id: '3-month',
    range: 90,
    name: '3 months',
  },
  {
    id: 'year',
    range: 365,
    name: 'Year',
  },
]

export { menuContact, menuLegal, menuTools, menusToolsChart }
