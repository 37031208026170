import React from 'react'
import styled from 'styled-components'
import MarkdownBody from '../MarkdownBody'

const Card = styled.div`
  width: 100%;
  h4 {
    margin: 0;
    padding: 0rem 0 0.75rem;
  }
`

const OfficeTitle = styled.h4`
  font-size: ${props => props.theme.type.sizes.base};
  font-family: ${props => props.theme.type.fonts.base};
  font-weight: normal;
  text-transform: uppercase;
`

const DetailsContainer = styled.ul`
  width: 100%;
  list-style-type: none;
  display: flex;
  padding: 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
  align-items: flex-start;
`

const OfficeDetails = styled.li`
  flex: 1 1 auto;
  align-self: auto;
  padding: 0 1rem 2rem 0;
  flex-direction: column;
  width: 100%;

  div {
    font-size: ${props => props.theme.type.sizes.base};
    font-weight: bold;
  }

  a {
    transition: ${props => props.theme.animations.transition};
  }

  /* stylelint-disable */
  address p,
  address p:first-child,
  span,
  openinghours p,
  openinghours p:first-child {
    font-size: ${props => props.theme.type.sizes.base};
    font-weight: normal !important;
    font-style: normal !important;
    margin: 0 !important;
    line-height: 1.6;
  }
  /* stylelint-enable */
  &:nth-child(3) {
    padding: 0;
  }

  @media (min-width: ${props => props.theme.responsive.medium}) {
    flex-direction: row;
    width: 33%;
  }
`

const LeadershipCard = ({ office: { address, contactNumber, title, openingHours } }) => (
  <Card>
    {title && <OfficeTitle>{title}</OfficeTitle>}
    <DetailsContainer>
      <OfficeDetails>
        <div>Address</div>
        <MarkdownBody body={address} as="address" />
      </OfficeDetails>

      <OfficeDetails>
        <div>Contact number</div>
        <a href={`tel:${contactNumber}`}>{contactNumber}</a>
      </OfficeDetails>

      <OfficeDetails>
        <div>Opening hours</div>
        <MarkdownBody body={openingHours} as="openinghours" />
      </OfficeDetails>
    </DetailsContainer>
  </Card>
)

export default LeadershipCard
