import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import OfficeCard from '../OfficeCard'
import Section from '../Section'
import SectionTitle from '../SectionTitle'

const Flex = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
  align-items: flex-start;
  list-style-type: none;
  padding: 0;
  width: 100%;
  margin: -5.25rem 0 0 0;

  &::after {
    content: '';
    flex: 0 0 30%;
  }
`

const CardContainer = styled.li`
  display: flex;
  flex: 1 1 auto;
  flex-grow: 1;
  width: 100%;
`

const CardWrapper = styled.div`
  width: 100%;
`

const CardTitle = styled.h1`
  font-size: 17px;
  font-weight: normal;
  margin-top: 60px;
  h2 {
    margin: 0;
  }
`

const NestedList = styled.ul`
  padding: 0;
`

const NestedContainer = styled.li`
  list-style-type: none;
  display: flex;
  flex: 1 1 auto;
  flex-grow: 1;
  width: 100%;
`

const Offices = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulOfficeVariant(sort: { fields: [order], order: ASC }) {
          edges {
            node {
              id
              title
              officeReference {
                order
                id
                contactNumber
                title
                openingHours {
                  childMarkdownRemark {
                    timeToRead
                    html
                    excerpt(pruneLength: 320)
                  }
                }
                address {
                  childMarkdownRemark {
                    timeToRead
                    html
                    excerpt(pruneLength: 320)
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Section borderBottom={false} paddingBottom={true} noSectionPadding={true} >
          <Flex>
            {data.allContentfulOfficeVariant.edges.map(({ node: office }) => (
              <CardContainer key={office.id}>
                <CardWrapper>
                  <CardTitle>
                    <SectionTitle>{office.title}<span>.</span></SectionTitle>
                  </CardTitle>
                  <NestedList>
                    {office.officeReference.map(item => (
                      <NestedContainer key={item.id}>
                        <OfficeCard key={item.id} office={item} />
                      </NestedContainer>
                    ))}
                  </NestedList>
                </CardWrapper>
              </CardContainer>
            ))}
          </Flex>
        </Section>
      )
    }}
  />
)

export default Offices
